<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ServiceContractManager_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div *showWithRoles="['ESO2002', 'DRCDOF', 'ESO2011']"  class="col-md-auto ml-auto d-flex align-items-center">
              <button *showWithRoles="['ESO2011']" class="btn btn-outline-primary d-inline-flex align-items-center mr-3"
                (click)="goToMultiDownloadSign(by)">
                <span class="material-icons-outlined mr-1">download</span> Tải hợp đồng
              </button>
                <div ngbDropdown container="body">
                    <button ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                        <span class="ct-icon-font icon-custom_signature mr-1"></span> {{ 'ServiceContractManager_dropdown_0' | translate }}
                    </button>
                    <div ngbDropdownMenu>
                        <div ngbDropdownItem>
                           <a class="cursor-pointer" (click)="goToSignMultiple('IMAGE')" >{{ 'ServiceContractManager_dropdown_1' | translate }}</a>
                        </div>
                        <div ngbDropdownItem>
                            <a class="cursor-pointer" (click)="goToSignMultiple('SMARTCA')" >{{ 'ServiceContractManager_dropdown_2' | translate }}</a>
                        </div>
                        <div ngbDropdownItem>
                            <a class="cursor-pointer" (click)="goToNoSignMultiple()">Duyệt không ký</a>
                        </div>
                    </div>
                </div>
                <button *ngIf="controlAccount.isAccLevel2" (click)="addContract()" class="btn btn-primary d-inline-flex"><span
                    class="material-icons mr-1">add</span> {{ 'btn_addnew' | translate }}</button>
            </div>
        </div>
    </div>
  <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li *ngIf="controlAccount.isAccLevel2" class="nav-item nav-material" [class.active]="controlAccount.isAccLevel2">
                    <a class="nav-link d-flex" [class.active]="controlAccount.isAccLevel2" id="all-tab" data-toggle="pill"
                        href="#all" role="tab" aria-controls="all" [attr.aria-selected]="controlAccount.isAccLevel2" (click)="selectTab(1)">
                        {{ 'ServiceContractManager_tab_1' | translate }}
                    </a>
                </li>
                <li class="nav-item nav-material" [class.active]="!controlAccount.isAccLevel2">
                    <a class="nav-link d-flex" [class.active]="!controlAccount.isAccLevel2" id="waitingForSign-tab" data-toggle="pill" href="#waitingForSign" role="tab"
                        aria-controls="waitingForSign" [attr.aria-selected]="!controlAccount.isAccLevel2" (click)="selectTab(2)">
                        {{ 'ServiceContractManager_tab_2' | translate }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK Hợp đồng đã gửi -->
            <div class="position-relative tab-pane fade" id="all" role="tabpanel" aria-labelledby="all"
                [class.show]="controlAccount.isAccLevel2" [class.active]="controlAccount.isAccLevel2">
                <app-service-contract-list-v2 #ownerList by="OWNER" [isAccLevel2]="isAccLevel2"></app-service-contract-list-v2>
            </div>

            <!--MARK Hợp đồng đã nhận  -->
            <div class="position-relative tab-pane fade" id="waitingForSign" role="tabpanel"
                aria-labelledby="waitingForSign" [class.show]="!controlAccount.isAccLevel2"
                [class.active]="!controlAccount.isAccLevel2">
                <app-service-contract-list-v2 #assigneeList by="ASSIGN" [isAccLevel2]="isAccLevel2"></app-service-contract-list-v2>
            </div>
        </div>
    </div>
</section>
